import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Work from "../components/Work"

import getDisciplinesFromQuery from "../../util/getDisciplinesFromQuery"

class TagRoute extends React.Component {
  render() {
    const {
      location: { search = "" },
    } = this.props
    const posts = this.props.data.allMarkdownRemark.edges.map(post => {
      const {
        frontmatter: {
          title,
          description,
          author,
          heroImage,
          previewImage,
          date,
          disciplines,
        },
        html,
        fields: { slug },
      } = post.node
      return {
        slug,
        title,
        description,
        author,
        date,
        disciplines,
        heroImage,
        previewImage,
        html,
      }
    })

    const discipline = this.props.pageContext.discipline
    const title = this.props.data.site.siteMetadata.title

    const group = [
      {
        fieldValue: discipline,
        totalCount: posts.length,
      },
    ]
    return (
      <Layout backgroundColor={"white"} isArchive>
        <Helmet title={`${discipline} | ${title}`} />

        <Work
          discipline={discipline}
          group={group}
          posts={posts}
          selectedDisciplines={getDisciplinesFromQuery(search)}
          pathname={"/work"}
        />
      </Layout>
    )
  }
}

export default TagRoute

export const disciplinePageQuery = graphql`
  query TagPage($discipline: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { disciplines: { in: [$discipline] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            heroImage
            previewImage
            title
            description
            author
            date
            disciplines
          }
          html
        }
      }
    }
  }
`
